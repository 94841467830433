<template>
  <div>
    <transition name="fade">
      <CCard color="">
        <CCardHeader>
          Student Class Mapping
          <div class="card-header-actions">
          </div>
        </CCardHeader>
          <CCardBody>
                <b-form  style="font-size:1rem;">
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group
                                id="input-group-1"
                                :label="labels.studentName +':'"
                                label-for="input-1"
                                style="text-align:left"
                                >
                                <select class="form-control" v-model="form.studentName">
                                    <option value=''>Select Student</option>
                                    <option v-for="(item,index) in student_list" :key="index" :value="item.student_id">
                                    {{item.student_name}}
                                    </option>
                                </select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                id="input-group-1"
                                :label="labels.className +':'"
                                label-for="input-1"
                                style="text-align:left"
                                >
                                <select class="form-control" v-model="form.className">
                                    <option value=''>Select Class</option>
                                    <option v-for="(item,index) in class_list" :key="index" :value="item.class_id">
                                    {{item.class_standard}}
                                    </option>
                                </select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6">
                            <b-form-group
                                id="input-group-1"
                                :label="labels.year +':'"
                                label-for="input-1"
                                style="text-align:left"
                                >
                                <select class="form-control" v-model="form.year">
                                    <option value=''>Select Year</option>
                                    <option v-for="(item,index) in year_list" :key="index" :value="item.ac_year_id">
                                    {{item.ac_year_description}}
                                    </option>
                                
                                </select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                id="input-group-1"
                                :label="labels.divisionName +':'"
                                label-for="input-1"
                                style="text-align:left"
                            >
                                <select class="form-control" v-model="form.divisionName">
                                    <option value=''>Select Division</option>
                                    <option v-for="(item,index) in division_list" :key="index" :value="item.division_id">
                                    {{item.division}}
                                    </option>
                                </select>
                            </b-form-group>
                        </div>
                    </div>
                </b-form>
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-4">
                        <b-button type="submit" @click="submit_data" variant="warning" class="mx=2">{{labels.submit}}</b-button>
                        <b-button type="reset" @click="reset_data" variant="light" class="mx-2">{{labels.reset}}</b-button>
                    </div>
                </div>
          </CCardBody>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;
export default {
  name: "StudentClass",
  components: {},
  data: function() {
    return {
        class_list:[],
      student_list:[],
      division_list:[],
      year_list:[],
      form: {
        userName: "",
        studentName:"",
        divisionName:"",
        year:"",
        className:"",
        password: "",
        checked: []
      },
      foods: ["Remember Me"],
      show: true,
      labelsEnglish: {
        title: "Student-Class Mapping",
        studentName: "Student",
        className: "Class",
        divisionName:"Division",
        year:"Year",
        submit: "Submit",
        reset: "Reset"
      },
      labels: {
        title: "Student-Class Mapping",
        studentName: "Student Name",
        className: "Class",
        divisionName:"Division",
        year:"Academic Year",
        submit: "Submit",
        reset: "Reset"
      },
      labelsMarathi: {
        title: "शाळा सूची",
        studentName: "विद्यार्थी",
        divisionName: "विभागणी ",
        year:"वर्ष",
        className: "वर्ग",
        submit: "स्वीकारा",
        reset: "पुनर्स्थापित"
      },

    };
  },
  computed:{
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
  },
  methods:{
     reset_data(){
      this.form.studentName = '';
      this.form.className = '';
      this.form.year = '';
      this.form.divisionName = '';
    },
    submit_data(){
      var data = {
        student_id: this.form.studentName,
        class_id: this.form.className,
        ac_year_id: this.form.year,
       // division_id: this.form.divisionName
       division_id: 1
      };
      var promise = apis.add_studentClassMapping(data).then(response => {
         this.$fire({
            text: "Student Mapped Sucessfully !!",
            type: "",
            timer: 3000,
            size:"xs",
            width:350,
            heightAuto: 'false'
          });
        //alert('Data Mapped');
      }).catch( error => {

      })
    },
    get_class(){
      var promise = apis.list_ClassMasters().then( response => {
        this.class_list = response.data;
      }).catch( error => {

      })
    },
    get_student(){
      var promise = apis.list_Students().then( response => {
        this.student_list = response.data;
      }).catch( error => {

      })
    },
    get_division(){
     var promise = apis.list_DivisionMasters().then( response => {
        this.division_list = response.data;
      }).catch( error => {

      })
    },
    get_year(){
      var promise = apis.list_AcademicYears().then( response => {
        this.year_list = response.data;
      }).catch( error => {

      })
    },
    info(row){
        console.log(row);
       this.$router.push({ name: "addSchool",params:{
           items: row
       }});
    },
    onSubmit(event) {
      event.preventDefault();
      
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.userName = "";
      this.form.password = "";
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
  
  },
  created(){
    this.get_class();
    this.get_student();
    this.get_division();
    this.get_year();
  }
};
</script>
